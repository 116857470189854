@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-xl font-semibold;
  }
  h3 {
    @apply text-lg font-semibold;
  }
}
